<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="标题" prop="title">
				  <el-input v-model="form.title" placeholder="请输入标题" type="text" clearable />
				</el-form-item>
				<el-form-item label="作者" prop="anthor">
				  <el-input v-model="form.anthor" placeholder="请输入作者" type="text" clearable />
				</el-form-item>
				<el-form-item label="类型" prop="type_id">
					 <el-select v-model="form.type_id" placeholder="请选择文章类型" size="medium" class="w-100">
					   <el-option v-for="(item,index) in types" :key="index"
						 :label="item | tree"
						 :value="item.id">
					   </el-option>
					 </el-select>
				</el-form-item>
				<el-form-item label="图片" prop="url">
					 <el-upload
					   class="upload-border position-relative"
					   action="/manage/images/upload"
					   :show-file-list="false"
					   :on-success="uploadSuccess">
					   <img v-if="form.cover != ''" :src="form.url" class="w-100 h-100">
					   <i v-else class="el-icon-plus uploader-icon"></i>
					   <i v-if="form.cover != ''" class="el-icon-delete text-white position-absolute p-1" style="top: -6px;right: 0;background-color: rgba(0,0,0,0.4);cursor: pointer;" @click.stop="delFile"></i>
					 </el-upload>
				</el-form-item>
				<el-form-item label="内容">
				   <tinymce ref="editor" :key="tinymceFlag" v-model="form.content" />
				</el-form-item>
				<el-form-item  label="发布状态">
				   <el-radio-group v-model="form.status">
					<el-radio :label="1">发布</el-radio>
					<el-radio :label="2">未发布</el-radio>
				   </el-radio-group>
				 </el-form-item>
				<el-form-item label="排序" prop="sort">
				  <el-input v-model="form.sort" placeholder="请输入排序" type="text" clearable />
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
import tinymce from "@/components/common/tinymce.vue"
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
		tinymce
	},
	data() {
		return {
			preUrl: 'article',
			autoRequest: false,
			form:{
				title:'',
				anthor:'平台发布',
				type_id:'',
				cover:'',
				url:'',
				content:'',
				sort:100,
				status:1,
			},
			rules:{
				title: [
				  { required: true, message: '请输入文章标题', trigger: 'blur' }
				],
				type_id: [
				  { required: true, message: '请选择文章类型', trigger: 'blur' }
				],
			},
			tinymceFlag:0,
			types:[],
		}
	},
	mounted() {
		this.getArticleTypes()
		if(this.$route.query.article){
			 this.form = this.$route.query.article
		}
	},
	methods:{
		delFile(){
			this.form.cover = ''
			this.form.url = ''
		},
		getArticleTypes(){
			this.axios.get(`/manage/article_type/getTree`, {
			  params: {}
			}).then(res=>{
			  if(res.status){
				this.types = res.data
				this.$forceUpdate()
			  }
			})
		},
		uploadSuccess(e){
		   if(e.status){
			 this.form.cover = e.data.id
			 this.form.url = e.data.url
		   }
		},
		handleSubmit() {
		  this.$refs.form.validate(valid => {
		    if (valid) {
		      if (this.form.sort) {
		        this.form.sort = parseInt(this.form.sort)
		      }
			  this.form.images = this.images
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('编辑成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
		delImage(index){
			this.$confirm('删除该文件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let list = [...this.images]
				list.splice(index,1)
				this.images = list
			}).catch(() => {
				
			});
		},
	},
	filters: {
	    tree(item) {
	        if (item.level == 0) {
	          return item.name
	        }
	        let str = ''
	        for (let i = 0; i < item.level; i++) {
	          str += i == 0 ? '|--' : '--'
	        }
	        return str + ' ' +item.name;
	    }
	},
}
</script>

<style>
</style>